// Model
import { IEnvironment } from ".";

// Constants
const baseUrl: string = 'https://www.bildelsbasen.se';
export const seoImageUrl: string = `${baseUrl}/assets/media/general/inspection.jpg`;

export const environment: IEnvironment = {
  orgId: 'bildelsbasen',
  favicon: '/assets/media/logos/bildelsbasen/favicon.ico',
  logo: '/assets/media/logos/bildelsbasen/logo.png',
  logoDark: '/assets/media/logos/bildelsbasen/logo.png',
  logoSize: '30', // In px
  isChristmas: false,
  baseUrl: baseUrl,
  production: true,
  isSentryLoggingEnable: true,
  googleMapKey: 'AIzaSyDg4oGdZQ_bxETypyuaoklOSy_XEVgtUDE',
  apiBaseUrl: 'https://www.bildelsbasen.se',
  checkoutUrl: 'https://checkout.bildelsbasen.se',
  ownStockUrl: 'https://cid-x.bildelsbasen.se',
  sentryDsn: 'https://aaf157941d154f01ae49b68b0f8e04a8@o472375.ingest.sentry.io/4504253011394560',
  wsEndpoint: 'wss://test.bildelsbasen.se/ws/',
  stockNoPrefix: 'W',
  googleAnalytics: {
    isEnabled: true,
    measurementId: 'G-3QSLH82E7E',
    language: 'sv',
    currency: 'SEK'
  },
  defaultSort: {
    column: 'part_rank',
    direction: 'desc'
  },
  recaptcha: {
    siteKey: '6LdS7wgTAAAAAH67xnp9bfKkNFFDkzXccfi4i9ah',
  },
  zendesk: {
    isZendeskEnabled: false,
    subDomain: 'bildelsbasen',
    scriptUrl: "https://static.zdassets.com/ekr/snippet.js?key=33b7066c-23ac-447b-ba46-0dc880b371b1"
  },
  featureFlags: {
    is_header_enabled: true,
    is_footer_enabled: true
  },
  visitorSettings: {
    defaultLocale: 'sv-se',
    defaultCurrency: 'SEK',
    allowedLocale: ['sv-se', 'nb-no', 'en-se', 'es-es', 'da-dk', 'is-is', 'fr-fr', 'it-it', 'de-de'],
    allowedCurrency: ['SEK', 'NOK', 'USD', 'DKK', 'ISK', 'EUR']
  },
  vehicleTypeConfigFlag: {
    carParts: true,
    truckParts: true,
    mcScooter: true,
    caravan: true,
    snowmobile: true,
    tyresAndRims: true,
    other: true,
  },
  regnrCountries: [
    {
      id: 752,
      code: 'SE',
      country: 'Sweden',
      flag: './assets/media/flags/sweden.svg'
    },
    {
      id: 578,
      code: 'NO',
      country: 'Norway',
      flag: './assets/media/flags/norway.svg'
    },
    {
      id: 208,
      code: 'DK',
      country: 'Denmark',
      flag: './assets/media/flags/denmark.svg'
    },
    {
      id: 246,
      code: 'FI',
      country: 'Finland',
      flag: './assets/media/flags/finland.svg'
    },
  ],
  homeBannerSliders: [
    {
      image: 'assets/media/banners/bildelsbasen/banner-1.jpg',
    },
    {
      image: 'assets/media/banners/bildelsbasen/slider-bg2.webp'
    },

    {
      image: 'assets/media/banners/bildelsbasen/slider-bg3.webp'
    }
  ],
  seo: {
    'en-se': {
      title: 'Swedens largest range of car parts',
      description: 'Search among 9 million car parts',
    },
    'sv-se': {
      title: 'Sveriges största utbud av bildelar',
      description: 'Sök bland 9 miljoner bildelar',
    },
    'nb-no': {
      title: 'Sveriges største utvalg av bildeler',
      description: 'Søk blant 9 millioner bildeler',
    },
    'es-es': {
      title: 'La mayor gama de repuestos para automóviles de Suecia',
      description: 'Busque entre 9 millones de piezas de automóviles',
    },
    'da-dk': {
      title: 'Sveriges største udvalg af bildele',
      description: 'Søg blandt 9 millioner bildele',
    },
    'is-is': {
      title: 'Stærsta úrval Svíþjóðar af bílavarahlutum',
      description: 'Leitaðu meðal 9 milljón bílavarahluta',
    },
    'fr-fr': {
      title: 'La plus grande gamme de pièces automobiles en Suède',
      description: 'Recherchez parmi 9 millions de pièces automobiles',
    },
    'fi-fi': {
      title: 'Ruotsin suurin autonosien valikoima',
      description: 'Hae 9 miljoonan auton osan joukosta',
    },
    'it-it': {
      title: 'La più vasta gamma di ricambi per automobili in Svezia',
      description: 'Cerca tra 9 milioni di ricambi auto',
    },
    'de-de': {
      title: 'Schwedens größtes Sortiment an Autoteilen',
      description: 'Suchen Sie unter 9 Millionen Autoteilen',
    },
    fallback: 'en-se',
    tags: [
      { property: 'og:site_name', content: 'Bildelsbasen' },
      { property: 'og:type', content: 'website' },
      { property: 'og:image', content: seoImageUrl },
      { name: 'author', content: 'Bildelsbasen Nordic AB' },
      { name: 'description', property: 'og:description', content: '' },
      { name: 'robots', content: 'follow,index,notranslate' },
    ],
  },
};