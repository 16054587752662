// Angular
import { APP_INITIALIZER, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
// RxJS & Lodash
import { catchError, tap } from 'rxjs/operators';
import { of, Observable, ReplaySubject } from 'rxjs';
import { toLower, split } from 'lodash';
// Environment
import { environment } from '@environment';
// Constants
import { API_URL } from '@constants';

@Injectable({
    providedIn: 'root',
})
export class AppInitLoaderService {
    // Public
    company: any;
    domainWithoutCid: string;

    /**
     * Workaround for the problem where Guards and Resolvers don't wait for APP_INITIALIZER
     * if RouterModule initialNavigation: 'enabledBlocking' is set.
     * 'enabledBlocking' is required for server-side rendering to work.
     */
    appInitDoneSubject = new ReplaySubject<boolean>(1);

    /**
     * Creates an instance of AppInitLoaderService.
     * @param {HttpClient} http
     * @param {Document} document
     * @memberof AppInitLoaderService
     */
    constructor(
        private http: HttpClient,
        @Inject(DOCUMENT) private document: Document
    ) { }

    /**
     * Get ID company data from the server
     * @return {*}  {Observable<any>}
     * @memberof AppInitLoaderService
     */
    public init(): Observable<any> {
        try {
            const siteDomain = toLower(this.document.location.host);
            const host = split(siteDomain, '.');
            const ownStockHost = split(host[0], '-');
            const ownStockId = ownStockHost[1];

            if (ownStockHost[0] !== 'cid' || !ownStockId) {
                return this.returnEmpty();
            }

            const applicationName = ownStockHost[2] ? ownStockHost[2] : host[1];

            // Remove 'cid' from the domain
            let cleanedDomain = siteDomain.replace(/^cid-\d+-?/, '');

            if (cleanedDomain.startsWith('.')) {
                cleanedDomain = cleanedDomain.slice(1);
            }

            this.domainWithoutCid = `https://${cleanedDomain}`;

            return this.http
                .get(`${environment.apiBaseUrl}${API_URL.COMPANY_INFO_URL}?id=eq.${ownStockId}`, {
                    headers: {
                        accept: 'application/vnd.pgrst.object+json',
                        prefer: 'params=single-object',
                        'x-application-name': applicationName,
                        'x-application-company-id': ownStockId,
                    },
                })
                .pipe(
                    tap((data) => {
                        this.company = { ...data, ownStockId, applicationName };
                        this.appInitDoneSubject.next(true);
                    }),
                    catchError(() => this.returnEmpty())
                );
        } catch (error) {
            return this.returnEmpty();
        }
    }

    /**
     * Return empty data
     * @private
     * @returns {Observable<never>}
     * @memberof AppInitLoaderService
     */
    private returnEmpty(): Observable<any> {
        this.appInitDoneSubject.next(true);
        return of([]);
    }
}

/**
 * APP Initialize Factory Service
 * @param {AppInitLoaderService} appLoader
 * @return {*}
 */
function AppInitFactoryService(appLoader: AppInitLoaderService) {
    return () => appLoader.init();
}

/** APP INIT PROVIDER */
export const APP_INIT_PROVIDER = {
    provide: APP_INITIALIZER,
    useFactory: AppInitFactoryService,
    deps: [AppInitLoaderService],
    multi: true,
};