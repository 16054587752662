// Angular
import { Injectable } from '@angular/core';
// Sentry
import * as Sentry from '@sentry/angular-ivy';
// Environment
import { environment } from '@environment';
// RxJs & Lodash
import { noop } from 'lodash';
import { BehaviorSubject } from 'rxjs';
// Service
import { GlobalErrorHandler } from './global-error-handler.service';
// Model
import { ISentryUserIdentity } from '@shared/models';
// Package.JSON
import packageInfo from 'package.json';

@Injectable()
export class SentryService {

    // Public
    loadingSubject = new BehaviorSubject<boolean>(false);
    loading$ = this.loadingSubject.asObservable();

    /**
     * Servce constructor
     * @param globalErrorHandler: GlobalErrorHandler
     */
    constructor(
        private readonly globalErrorHandler: GlobalErrorHandler
    ) {
        environment.isSentryLoggingEnable ? this.initSentry() : noop();
    }

    /**
     * Initialise the Sentry Logging
     */
    public initSentry(): void {
        Sentry.init({
            dsn: environment.sentryDsn,
            maxValueLength: 500,
            environment: environment.production ? 'PROD' : 'DEV',
            release: `${packageInfo.name}-v${packageInfo.version}`,
            ignoreErrors: [
                'Non-Error exception captured',
                '0 Unknown Error',
                'ChunkLoadError',
                'InvalidStateError',
                'Error: NG02100',
                "'text/html' is not a valid JavaScript MIME type"
            ],
            beforeSend(event, hint) {
                if (hint.originalException === 'Timeout') return null;
                return event;
            }
        });
    }

    /**
     * Set User identity information to sentry for more detail view
     * @param payload: ISentryUserIdentity
     */
    public setUser(payload: ISentryUserIdentity): void {
        const scope = Sentry.getCurrentScope();
        scope.setUser(payload);
    }

    /**
     * Set Tags to sentry issue
     * @param tag: string
     * @example https://docs.sentry.io/enriching-error-data/additional-data/#tags
     */
    public setTag(key: string, value: string): void {
        const scope = Sentry.getCurrentScope();
        scope.setTag(key, value);
    }

    /**
     * Rest Sentry config when user logged out
     */
    public resetAll(): void {
        const scope = Sentry.getCurrentScope();
        scope.setUser({ email: '' });
    }

    /**
     * Open Sentry Report Dialog Modal
     * @param user: user details
     */
    public openFeedbackDialog(user: any): void {
        try {
            const eventId: string = Sentry.captureMessage(`${user.id} | ${user.name} | ${user.email}  | Reported Technical issue`);
            const option = {
                eventId,
                title: 'Report Technical Issue',
                subtitle: 'Our team will be notified. If you would like to help, tell us what happened below.',
                subtitle2: '',
                labelSubmit: 'Report Issue',
                user: {
                    email: user.email,
                    name: user.name
                },
                onLoad: () => {
                    this.loadingSubject.next(false);
                }
            };
            Sentry.showReportDialog(option);
        } catch (error) {
            this.globalErrorHandler.handleError(error);
        }
    }
}
